import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useSearchParams } from "react-router-dom";
import * as Constants from "../constants";

export default function ServiceUnavailable() {

    const { isAuthenticated, logout } = useAuth0();
    const [searchParams] = useSearchParams();
    const message = searchParams.get("message");

    useEffect(() => {
        if (isAuthenticated) {
            if (Constants.DEBUG) { console.log("Service unavailable, %s. Logging user out from Auth0...", message); }
            logout({ localOnly: true, });
            localStorage.clear();
            sessionStorage.clear();
        }
    }, [isAuthenticated, logout]);

    return (
        <>
            <div style={{ margin: "0 auto" }}>

                <h1>Queensland Digital Licence App</h1>

                <div className="alert alert-danger" role="alert">
                    <h2><i className="fa fa-times-circle" style={{ float: "left" }}></i>Service unavailable</h2>

                    <p>
                        Queensland Digital Identity is currently unavailable.
                        The Department of Transport and Main Roads apologises for any inconvenience.
                    </p>
                    
                    <p>If you require immediate assistance please <a href={Constants.CONTACT_US_URL}>contact us</a>.</p>
                </div>

            </div>
        </>
    )
}